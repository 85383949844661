.each-slide img {
    padding: 20px;
    padding-top: 0px;
  }

.each-slide > div {
    /* display: flex;
    align-items: flex-end;
    justify-content: center; */
    background-size: cover;
    height: 600px;
  }
  
  .each-slide span {
    padding: 15px;
    font-size: 20px;
    color:"#15c9b9"; 
    font-family: "Ubuntu";
    background: #efefef;
    text-align: center;
    border-radius: 0 0 20px 0;
  }